.social-media-sidebar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease;
}

.toggle-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 10px;
}

.icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-icon {
    font-size: 24px;
    margin: 5px 0;
    transition: transform 0.3s ease;
}

.social-icon.facebook {
    color: #3b5998;
}

.social-icon.instagram {
    color: #e4405f;
}

.social-icon.twitter {
    color: #1da1f2;
}

.social-icon.linkedin {
    color: #0077b5;
}

.social-icon.whatsapp {
    color: #25d366;
}

.social-icon:hover {
    transform: scale(1.1);
}

.social-media-sidebar.open {
    transform: translateY(-50%) translateX(-100%);
} 