.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 40px;
    background-color: #f8f9fa;
}

.service-card {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    backdrop-filter: blur(5px);
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
}

.service-icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: #0056b3;
}

.service-title {
    font-size: 20px;
    color: #004085;
    margin-bottom: 10px;
}

.service-description {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.service-link {
    text-decoration: none;
    color: #0056b3;
    font-weight: bold;
    transition: color 0.3s ease;
}

.service-link:hover {
    color: #004085;
}

.services {
    padding: 2rem 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}

@media (min-width: 640px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }
} 