.hero {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.hero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 20px;
}

.hero h1 {
    font-size: 3.5rem;
    margin-bottom: 15px;
}

.hero p {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.btn-primary, .btn-secondary {
    padding: 12px 25px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary {
    background-color: #0056b3;
    color: #fff;
}

.btn-secondary {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
}

.btn-primary:hover, .btn-secondary:hover {
    background-color: #004085;
    transform: translateY(-3px);
} 