.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
}

.nav-links a {
    color: #fff;
    margin-left: 20px;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.nav-links a:hover {
    color: #ccc;
    transform: translateY(-2px);
} 