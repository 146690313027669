.footer {
    background-color: #004085;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-links, .footer-social, .footer-contact {
    margin: 10px;
}

.footer-links a, .footer-social a {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
    transition: color 0.3s ease;
}

.footer-links a:hover, .footer-social a:hover {
    color: #0056b3;
} 